import Arcade4FunHero from "../assets/projects/arcade4fun/hero.jpeg";
import Arcade4FunThumbnail from '../assets/projects/arcade4fun/thumb.jpeg';
import Arcade4FunIcon from '../assets/projects/arcade4fun/icon.jpeg';
import Arcade4FunBanner from '../assets/projects/arcade4fun/banner.jpeg';
import Arcade4FunScreenshot1 from '../assets/projects/arcade4fun/screenshot1.jpeg';
import Arcade4FunScreenshot2 from '../assets/projects/arcade4fun/screenshot2.jpeg';

import PocketHamsterManiaHero from "../assets/projects/pockethamstermania/hero.jpeg";
import PocketHamsterManiaThumbnail from '../assets/projects/pockethamstermania/thumb.jpeg';
import PocketHamsterManiaIcon from '../assets/projects/pockethamstermania/icon.jpeg';
import PocketHamsterManiaBanner from '../assets/projects/pockethamstermania/banner.jpeg';
import PocketHamsterManiaScreenshot1 from '../assets/projects/pockethamstermania/screenshot1.jpeg';
import PocketHamsterManiaScreenshot2 from '../assets/projects/pockethamstermania/screenshot2.jpeg';
import PocketHamsterManiaScreenshot3 from '../assets/projects/pockethamstermania/screenshot3.jpeg';
import PocketHamsterManiaScreenshot4 from '../assets/projects/pockethamstermania/screenshot4.jpeg';
import PocketHamsterManiaScreenshot5 from '../assets/projects/pockethamstermania/screenshot5.jpeg';
import PocketHamsterManiaScreenshot6 from '../assets/projects/pockethamstermania/screenshot6.jpeg';
import PocketHamsterManiaScreenshot7 from '../assets/projects/pockethamstermania/screenshot7.jpeg';
import PocketHamsterManiaScreenshot8 from '../assets/projects/pockethamstermania/screenshot8.jpeg';

import YatamaHero from '../assets/projects/yatama/hero.jpeg';
import YatamaThumbnail from '../assets/projects/yatama/thumb.jpeg';
import YatamaIcon from '../assets/projects/yatama/icon.jpeg';
import YatamaBanner from '../assets/projects/yatama/banner.jpeg';
import YatamaScreenshot1 from '../assets/projects/yatama/screenshot1.webp';
import YatamaScreenshot2 from '../assets/projects/yatama/screenshot2.webp';
import YatamaScreenshot3 from '../assets/projects/yatama/screenshot3.webp';
import YatamaScreenshot4 from '../assets/projects/yatama/screenshot4.webp';
import YatamaScreenshot5 from '../assets/projects/yatama/screenshot5.webp';
import YatamaScreenshot6 from '../assets/projects/yatama/screenshot6.webp';
import YatamaScreenshot7 from '../assets/projects/yatama/screenshot7.webp';

import FilbingBoxHero from '../assets/projects/filbingbox/hero.jpeg';
import FilbingBoxThumbnail from '../assets/projects/filbingbox/thumb.jpeg';
import FilbingBoxIcon from '../assets/projects/filbingbox/icon.jpeg';
import FilbingBoxBanner from '../assets/projects/filbingbox/banner.jpeg';
import FilbingBoxScreenshot1 from '../assets/projects/filbingbox/screenshot1.jpeg';
import FilbingBoxScreenshot2 from '../assets/projects/filbingbox/screenshot2.jpeg';
import FilbingBoxScreenshot3 from '../assets/projects/filbingbox/screenshot3.jpeg';
import FilbingBoxScreenshot4 from '../assets/projects/filbingbox/screenshot4.webp';
import FilbingBoxScreenshot5 from '../assets/projects/filbingbox/screenshot5.webp';

import TrackAndBurnHero from '../assets/projects/trackandburn/hero.jpeg';
import TrackAndBurnThumbnail from '../assets/projects/trackandburn/thumb.jpeg';
import TrackAndBurnIcon from '../assets/projects/trackandburn/icon.jpeg';
import TrackAndBurnBanner from '../assets/projects/trackandburn/banner.jpeg';
import TrackAndBurnScreenshot1 from '../assets/projects/trackandburn/screenshot1.jpeg';
import TrackAndBurnScreenshot2 from '../assets/projects/trackandburn/screenshot2.jpeg';
import TrackAndBurnScreenshot3 from '../assets/projects/trackandburn/screenshot3.jpeg';
import TrackAndBurnScreenshot4 from '../assets/projects/trackandburn/screenshot4.jpeg';
import TrackAndBurnScreenshot5 from '../assets/projects/trackandburn/screenshot5.jpeg';
import TrackAndBurnScreenshot6 from '../assets/projects/trackandburn/screenshot6.jpeg';

import SSBEHero from '../assets/projects/ssbe/hero.jpeg';
import SSBEThumbnail from '../assets/projects/ssbe/thumb.jpeg';
import SSBEIcon from '../assets/projects/ssbe/icon.jpeg';
import SSBEBanner from '../assets/projects/ssbe/banner.jpeg';
import SSBEScreenshot1 from '../assets/projects/ssbe/screenshot1.jpeg';
import SSBEScreenshot2 from '../assets/projects/ssbe/screenshot2.jpeg';

import JetItNowHero from '../assets/projects/jetitnow/hero.jpeg';
import JetItNowThumbnail from '../assets/projects/jetitnow/thumb.jpeg';
import JetItNowIcon from '../assets/projects/jetitnow/icon.jpeg';
import JetItNowBanner from '../assets/projects/jetitnow/banner.jpeg';
import JetItNowScreenshot1 from '../assets/projects/jetitnow/screenshot1.webp';
import JetItNowScreenshot2 from '../assets/projects/jetitnow/screenshot2.webp';
import JetItNowScreenshot3 from '../assets/projects/jetitnow/screenshot3.webp';
import JetItNowScreenshot4 from '../assets/projects/jetitnow/screenshot4.webp';
import JetItNowScreenshot5 from '../assets/projects/jetitnow/screenshot5.webp';
import JetItNowScreenshot6 from '../assets/projects/jetitnow/screenshot6.webp';
import JetItNowScreenshot7 from '../assets/projects/jetitnow/screenshot7.webp';
import JetItNowScreenshot8 from '../assets/projects/jetitnow/screenshot8.webp';
import JetItNowScreenshot9 from '../assets/projects/jetitnow/screenshot9.webp';

import SketchesHero from '../assets/projects/sketches/hero.jpeg';
import SketchesThumbnail from '../assets/projects/sketches/thumb.jpeg';
import SketchesIcon from '../assets/projects/sketches/icon.jpeg';
import SketchesBanner from '../assets/projects/sketches/banner.jpeg';
import SketchesScreenshot1 from '../assets/projects/sketches/screenshot1.webp';
import SketchesScreenshot2 from '../assets/projects/sketches/screenshot2.webp';
import SketchesScreenshot3 from '../assets/projects/sketches/screenshot3.webp';
import SketchesScreenshot4 from '../assets/projects/sketches/screenshot4.webp';
import SketchesScreenshot5 from '../assets/projects/sketches/screenshot5.webp';
import SketchesScreenshot6 from '../assets/projects/sketches/screenshot6.webp';
import SketchesScreenshot7 from '../assets/projects/sketches/screenshot7.webp';
import SketchesScreenshot8 from '../assets/projects/sketches/screenshot8.webp';
import SketchesScreenshot9 from '../assets/projects/sketches/screenshot9.webp';

import BiodermaLiveQuizHero from '../assets/projects/biodermalivequiz/hero.jpeg';
import BiodermaLiveQuizThumbnail from '../assets/projects/biodermalivequiz/thumb.jpeg';
import BiodermaLiveQuizIcon from '../assets/projects/biodermalivequiz/icon.jpeg';
import BiodermaLiveQuizBanner from '../assets/projects/biodermalivequiz/banner.jpeg';
import BiodermaLiveQuizScreenshot1 from '../assets/projects/biodermalivequiz/screenshot1.jpeg';
import BiodermaLiveQuizScreenshot2 from '../assets/projects/biodermalivequiz/screenshot2.jpeg';
import BiodermaLiveQuizScreenshot3 from '../assets/projects/biodermalivequiz/screenshot3.jpeg';
import BiodermaLiveQuizScreenshot4 from '../assets/projects/biodermalivequiz/screenshot4.jpeg';
import BiodermaLiveQuizScreenshot5 from '../assets/projects/biodermalivequiz/screenshot5.jpeg';

import SillyMemoryHero from '../assets/projects/sillymemory/hero.jpeg';
import SillyMemoryThumbnail from '../assets/projects/sillymemory/thumb.jpeg';
import SillyMemoryIcon from '../assets/projects/sillymemory/icon.jpeg';
import SillyMemoryBanner from '../assets/projects/sillymemory/banner.jpeg';
import SillyMemoryScreenshot1 from '../assets/projects/sillymemory/screenshot1.webp';
import SillyMemoryScreenshot2 from '../assets/projects/sillymemory/screenshot2.webp';
import SillyMemoryScreenshot3 from '../assets/projects/sillymemory/screenshot3.webp';
import SillyMemoryScreenshot4 from '../assets/projects/sillymemory/screenshot4.webp';
import SillyMemoryScreenshot5 from '../assets/projects/sillymemory/screenshot5.webp';
import SillyMemoryScreenshot6 from '../assets/projects/sillymemory/screenshot6.webp';
import SillyMemoryScreenshot7 from '../assets/projects/sillymemory/screenshot7.webp';
import SillyMemoryScreenshot8 from '../assets/projects/sillymemory/screenshot8.webp';

import TheotokosHero from '../assets/projects/theotokos/hero.jpeg';
import TheotokosThumbnail from '../assets/projects/theotokos/thumb.jpeg';
import TheotokosIcon from '../assets/projects/theotokos/icon.jpeg';
import TheotokosBanner from '../assets/projects/theotokos/banner.jpeg';
import TheotokosScreenshot1 from '../assets/projects/theotokos/screenshot1.webp';
import TheotokosScreenshot2 from '../assets/projects/theotokos/screenshot2.webp';
import TheotokosScreenshot3 from '../assets/projects/theotokos/screenshot3.webp';
import TheotokosScreenshot4 from '../assets/projects/theotokos/screenshot4.webp';

import SkicaseHero from '../assets/projects/skicase/hero.jpeg';
import SkicaseThumbnail from '../assets/projects/skicase/thumb.jpeg';
import SkicaseIcon from '../assets/projects/skicase/icon.jpeg';
import SkicaseBanner from '../assets/projects/skicase/banner.jpeg';
import SkicaseScreenshot1 from '../assets/projects/skicase/screenshot1.jpeg';
import SkicaseScreenshot2 from '../assets/projects/skicase/screenshot2.jpeg';
import SkicaseScreenshot3 from '../assets/projects/skicase/screenshot3.jpeg';

import BiodermaCommerciauxHero from '../assets/projects/biodermacommerciaux/hero.jpeg';
import BiodermaCommerciauxThumbnail from '../assets/projects/biodermacommerciaux/thumb.jpeg';
import BiodermaCommerciauxIcon from '../assets/projects/biodermacommerciaux/icon.jpeg';
import BiodermaCommerciauxBanner from '../assets/projects/biodermacommerciaux/banner.jpeg';
import BiodermaCommerciauxScreenshot1 from '../assets/projects/biodermacommerciaux/screenshot1.jpeg';
import BiodermaCommerciauxScreenshot2 from '../assets/projects/biodermacommerciaux/screenshot2.jpeg';
import BiodermaCommerciauxScreenshot3 from '../assets/projects/biodermacommerciaux/screenshot3.jpeg';
import BiodermaCommerciauxScreenshot4 from '../assets/projects/biodermacommerciaux/screenshot4.jpeg';

import ImerysHero from '../assets/projects/imerys/hero.jpeg';
import ImerysThumbnail from '../assets/projects/imerys/thumb.jpeg';
import ImerysIcon from '../assets/projects/imerys/icon.jpeg';
import ImerysBanner from '../assets/projects/imerys/banner.jpeg';
import ImerysScreenshot1 from '../assets/projects/imerys/screenshot1.jpeg';
import ImerysScreenshot2 from '../assets/projects/imerys/screenshot2.jpeg';
import ImerysScreenshot3 from '../assets/projects/imerys/screenshot3.jpeg';
import ImerysScreenshot4 from '../assets/projects/imerys/screenshot4.jpeg';
import ImerysScreenshot5 from '../assets/projects/imerys/screenshot5.jpeg';
import ImerysScreenshot6 from '../assets/projects/imerys/screenshot6.jpeg';

import WatchLiveHero from '../assets/projects/watchlive/hero.jpeg';
import WatchLiveThumbnail from '../assets/projects/watchlive/thumb.jpeg';
import WatchLiveIcon from '../assets/projects/watchlive/icon.jpeg';
import WatchLiveBanner from '../assets/projects/watchlive/banner.jpeg';
import WatchLiveScreenshot1 from '../assets/projects/watchlive/screenshot1.webp';
import WatchLiveScreenshot2 from '../assets/projects/watchlive/screenshot2.webp';
import WatchLiveScreenshot3 from '../assets/projects/watchlive/screenshot3.webp';
import WatchLiveScreenshot4 from '../assets/projects/watchlive/screenshot4.webp';

import RichessesDuTerroirHero from '../assets/projects/richessesduterroir/hero.jpeg';
import RichessesDuTerroirThumbnail from '../assets/projects/richessesduterroir/thumb.jpeg';
import RichessesDuTerroirIcon from '../assets/projects/richessesduterroir/icon.jpeg';
import RichessesDuTerroirBanner from '../assets/projects/richessesduterroir/banner.jpeg';
import RichessesDuTerroirScreenshot1 from '../assets/projects/richessesduterroir/screenshot1.jpeg';
import RichessesDuTerroirScreenshot2 from '../assets/projects/richessesduterroir/screenshot2.jpeg';
import RichessesDuTerroirScreenshot3 from '../assets/projects/richessesduterroir/screenshot3.jpeg';
import RichessesDuTerroirScreenshot4 from '../assets/projects/richessesduterroir/screenshot4.jpeg';
import RichessesDuTerroirScreenshot5 from '../assets/projects/richessesduterroir/screenshot5.jpeg';

import SLVHero from '../assets/projects/slv/hero.jpeg';
import SLVThumbnail from '../assets/projects/slv/thumb.jpeg';
import SLVIcon from '../assets/projects/slv/icon.jpeg';
import SLVBanner from '../assets/projects/slv/banner.jpeg';
import SLVScreenshot1 from '../assets/projects/slv/screenshot1.webp';
import SLVScreenshot2 from '../assets/projects/slv/screenshot2.webp';
import SLVScreenshot3 from '../assets/projects/slv/screenshot3.webp';
import SLVScreenshot4 from '../assets/projects/slv/screenshot4.webp';

import WatchEssentialsHero from '../assets/projects/watchessentials/hero.jpeg';
import WatchEssentialsThumbnail from '../assets/projects/watchessentials/thumb.jpeg';
import WatchEssentialsIcon from '../assets/projects/watchessentials/icon.jpeg';
import WatchEssentialsBanner from '../assets/projects/watchessentials/banner.jpeg';
import WatchEssentialsScreenshot1 from '../assets/projects/watchessentials/screenshot1.webp';
import WatchEssentialsScreenshot2 from '../assets/projects/watchessentials/screenshot2.webp';
import WatchEssentialsScreenshot3 from '../assets/projects/watchessentials/screenshot3.webp';

import CDOEngineHero from '../assets/projects/cdoengine/hero.jpeg';
import CDOEngineThumbnail from '../assets/projects/cdoengine/thumb.jpeg';
import CDOEngineIcon from '../assets/projects/cdoengine/icon.jpeg';
import CDOEngineBanner from '../assets/projects/cdoengine/banner.jpeg';

import PuissanceNintendoHero from '../assets/projects/puissancenintendo/hero.jpeg';
import PuissanceNintendoThumbnail from '../assets/projects/puissancenintendo/thumb.jpeg';
import PuissanceNintendoIcon from '../assets/projects/puissancenintendo/icon.jpeg';
import PuissanceNintendoBanner from '../assets/projects/puissancenintendo/banner.jpeg';
import PuissanceNintendoScreenshot1 from '../assets/projects/puissancenintendo/screenshot1.webp';
import PuissanceNintendoScreenshot2 from '../assets/projects/puissancenintendo/screenshot2.webp';
import PuissanceNintendoScreenshot3 from '../assets/projects/puissancenintendo/screenshot3.webp';
import PuissanceNintendoScreenshot4 from '../assets/projects/puissancenintendo/screenshot4.webp';
import PuissanceNintendoScreenshot5 from '../assets/projects/puissancenintendo/screenshot5.webp';
import PuissanceNintendoScreenshot6 from '../assets/projects/puissancenintendo/screenshot6.webp';
import PuissanceNintendoScreenshot7 from '../assets/projects/puissancenintendo/screenshot7.webp';
import PuissanceNintendoScreenshot8 from '../assets/projects/puissancenintendo/screenshot8.webp';

const DataSource = [
    {
      image: Arcade4FunHero,
      thumbnail: Arcade4FunThumbnail,
      icon: Arcade4FunIcon,
      banner: Arcade4FunBanner,
      screenshots: [
        Arcade4FunScreenshot1,
        Arcade4FunScreenshot2,
      ],
      title: "Arcade 4 Fun",
      description: "Arcade 4 Fun est une borne d’arcade connectée, personnalisable et pensée pour les entreprises comme pour les événements festifs. Une expérience rétro moderne à installer dans une salle de pause, sur un stand ou en plein mariage !",
      info: "Arcade 4 Fun est une solution matérielle et logicielle tout-en-un développée par CDO Apps, qui permet de jouer à plus de 1000 classiques du rétro-gaming sur une borne au design personnalisable.<br /><br />Destinée à la location (événements pros, mariages, salons…) comme à la vente, elle s’adapte à son environnement avec des habillages visuels spécifiques et une interface logicielle personnalisée à l’image de la marque ou de l’événement.<br /><br />En plus de son catalogue de jeux classiques, Arcade 4 Fun propose un mode de personnalisation avancée pour les entreprises, permettant de créer un jeu exclusif, sur-mesure, pour transmettre un message ou renforcer une culture d’équipe.<br /><br />Développée autour d’une interface intuitive façon \"Netflix du rétro-gaming\", la borne offre une UX fluide et engageante, entièrement pensée pour être accessible à tous les publics, quel que soit leur niveau de familiarité avec le jeu vidéo.",
      focus: "CDO Apps est à l’origine du concept, du design logiciel et du développement complet de la solution Arcade 4 Fun. Nous avons conçu le prototype de la borne dans notre atelier, puis développé l’interface embarquée à l’aide de Flutter, en y intégrant un plugin natif personnalisé permettant la communication directe avec les éléments matériels (joysticks, boutons, …) via GPIO.<br /><br />Nous avons également travaillé à la conception d’un outil de gestion de contenu, permettant l’ajout de jeux au catalogue de manière centralisée, et à la mise en place d’un système de détection automatique de mises à jour sur chaque borne connectée.<br /><br />Enfin, nous avons collaboré avec des partenaires externes pour la direction artistique, la fabrication de l’habillage personnalisé et l’impression de la communication associée. Le projet a été présenté pour la première fois lors d’un salon grand public en avril 2025.",
      slug: "arcade-4-fun",
      tags: ["flutter","android"],
      techs: ["Flutter","C","Kotlin"],
      website: "https://arcade4.fun",
      features: [
        {
          title: "Interface embarquée sur mesure",
          description: "Développement d'une interface fluide et ergonomique en Flutter, pensée pour l’écran 24 pouces de la borne. L'interface s'inspire des plateformes de streaming modernes avec un système de navigation par catégorie ou titre, optimisé pour l’usage au joystick."
        },
        {
          title: "Compatibilité matérielle personnalisée",
          description: "Mise en place d’un plugin natif Flutter permettant la communication directe avec les composants physiques de la borne (boutons, joysticks, éclairages LED) via GPIO, assurant une réactivité maximale."
        },
        {
          title: "Catalogue de jeux rétro intégré",
          description: "Intégration d’un ensemble de jeux rétro sélectionnés pour leur accessibilité et leur convivialité. Le catalogue est évolutif et peut être enrichi à distance grâce à un système de mise à jour automatisé."
        },
        {
          title: "Personnalisation à plusieurs niveaux",
          description: "De l’habillage visuel à l’interface, chaque borne peut être adaptée à l’image de l’entreprise cliente. Nous proposons également le développement de jeux exclusifs selon des cahiers des charges spécifiques."
        },
        {
          title: "Distribution & présentation",
          description: "Création d’un kit de communication incluant une plaquette imprimée, un site vitrine one-page et un flyer B2C. Première présentation publique réalisée lors du Festival du Jeu de Pontault-Combault en avril 2025."
        }
      ]
    },
    {
      image: PocketHamsterManiaHero,
      thumbnail: PocketHamsterManiaThumbnail,
      icon: PocketHamsterManiaIcon,
      banner: PocketHamsterManiaBanner,
      screenshots: [
        PocketHamsterManiaScreenshot1,
        PocketHamsterManiaScreenshot2,
        PocketHamsterManiaScreenshot3,
        PocketHamsterManiaScreenshot4,
        PocketHamsterManiaScreenshot5,
        PocketHamsterManiaScreenshot6,
        PocketHamsterManiaScreenshot7,
        PocketHamsterManiaScreenshot8,
      ],
      title: "Pocket Hamster Mania",
      description: "Adoptez, nourrissez et collectionnez de petits hamsters mignons inspirés de la vie réelle et de la pop culture dans Pocket Hamster Mania&nbsp;! Un jeu fun et amusant pour les amoureux des animaux virtuels.",
      info: "Dans ce jeu de simulation idle, réalisé avec Unity, le joueur adopte des hamsters inspirés de la vie réelle et de la pop-culture (super-héros, personnages historiques, etc). Il y en a des dizaines et ils ont tous leur personnalité et leurs caractéristiques uniques.<br /><br />Il suffit de placer un hamster dans une activité puis d'attendre qu'il produise des graines : celles-ci sont ensuite à investir pour acheter de nouvelles activités ou bien améliorer celles déjà installées. En tout, 25 activités réparties dans 5 décors sont disponibles afin de créer le plus grand parc à hamsters possible.<br /><br />Enfin, après avoir rempli des quêtes, comme nourrir un certain nombre de hamsters ou atteindre un certain niveau de production, le joueur est récompensé avec de la monnaie virtuelle à dépenser dans un système gacha pour obtenir toujours plus de hamsters rares et légendaires.",
      focus: "Pocket Hamster Mania est un jeu mobile édité par CDO Apps. Pour celui-ci, nous sommes partis d'une feuille blanche avec l'idée de créer un jeu de collection d'animaux virtuels. Une fois le Game Design Document rédigé et validé, nous avons commencé le développement du jeu&nbsp;: nous avons donc recruté l'équipe artistique pour la réalisation des ressources graphiques et sonores et avons ensuite assuré la coordination entre celle-ci et le développement pour garantir une intégration fidèle à la spécification initiale.<br /><br />En parallèle, nous avons implémenté un back-end permettant de gérer en ligne les ressources de Pocket Hamster Mania et de proposer des événements spéciaux aux joueurs&nbsp;: ce faisant, nous avons déployé une API RESTful sécurisée pour garantir la confidentialité des données des joueurs. Enfin, nous nous sommes chargés de la publication du jeu sur les stores et avons confié à un partenaire la communication autour du jeu.",
      slug: "pocket-hamster-mania",
      tags: ["project-management","unity","ios","android"],
      techs: ["Unity","C#","Node.js","MongoDB"],
      website: "https://www.pockethamstermania.com/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/pocket-hamster-mania/id6470218141",
        googlePlay: "https://play.google.com/store/apps/details?id=com.cdoapps.idlehamsters",
      },
      features: [
        {
          title: "Direction artistique",
          description: "Utilisation d'Adobe Illustrator pour la création des assets graphiques et Spine pour l'animation squelettique, permettant des personnages mignons et expressifs."
        },
        {
          title: "Ambiance sonore",
          description: "Composition de bruitages et musiques spécifiques au jeu, avec synchronisation des sons et animations gérée par des scripts Unity."
        },
        {
          title: "Achats intégrés",
          description: "Implémentation du système Unity IAP pour gérer les achats in-app de graines (monnaie virtuelle) sur les plateformes iOS et Android."
        },
        {
          title: "Monétisation par la publicité",
          description: "Intégration de Unity LevelPlay pour la médiation publicitaire, permettant l'affichage de publicités vidéo en échange de récompenses in-game."
        },
        {
          title: "Back-end sécurisé",
          description: "Stockage des données des joueurs sur un serveur Node.js, sécurisé par un système d'authentification JWT."
        },
        {
          title: "Web app de gestion",
          description: "Développement d'une interface de gestion des ressources du jeu en Flutter, permettant la création et la gestion d'événements spéciaux pour les joueurs."
        }
      ]
    },
    {
      image: YatamaHero,
      thumbnail: YatamaThumbnail,
      icon: YatamaIcon,
      banner: YatamaBanner,
      screenshots: [
        YatamaScreenshot1,
        YatamaScreenshot2,
        YatamaScreenshot3,
        YatamaScreenshot4,
        YatamaScreenshot5,
        YatamaScreenshot6,
        YatamaScreenshot7,
      ],
      title: "Tayasui Yatama",
      description: "Yatama n'est pas seulement un personnage super mignon à peindre, c'est la clé de votre créativité. Son interface conviviale est parfaite pour les artistes de tous les âges.",
      info: "Dessinez en temps réel sur un personnage en 3D, avec des outils de peinture réalistes et des effets de lumière dynamiques&nbsp;: Yatama est la première app de son genre sur l'App Store.<br /><br />Son interface utilisateur conviviale et intuitive permet aux artistes de tous âges et de tous niveaux de créer des œuvres d'art uniques et originales grâce aux outils de peinture déjà connus de Tayasui Sketches.<br /><br />Avec ses huits compagnons additionnels, Yatama est un excellent moyen de se détendre et de laisser libre cours à sa créativité.",
      focus: "Après avoir développé le moteur de dessin pour Tayasui Sketches, nous avons collaboré avec Tayasui pour créer Yatama, un personnage à peindre. Le défi était d'intégrer les capacités de dessin au doigt et à l'Apple Pencil dans un environnement 3D interactif&nbsp;: le moteur est en effet initialement conçu pour peindre sur une surface 2D, il nous fallait donc implémenter puis optimiser une projection 3D pour donner à l'utilisateur une expérience de peinture immersive.<br /><br />Ce moteur dédié intègre également des techniques de rendu rarement utilisées dans les applications mobiles, comme l'éclairage basé sur des images HDR (High Dynamic Range) donnant l'illusion de peindre à la lumière naturelle.",
      slug: "yatama",
      tags: ["graphics","ios"],
      techs: ["Swift","Objective-C","C","OpenGL"],
      website: "https://www.tayasui.com/yatama/",
      stores: {
        appStore: "https://apps.apple.com/us/app/tayasui-yatama/id6469051887",
      },
      features: [
        {
          title: "Import des modèles 3D",
          description: "Création d'un outil d'import Collada pour charger des modèles 3D dans l'application, en ne conservant que les informations de vertex et de texture nécessaires au dessin."
        },
        {
          title: "Mode de dessin 3D",
          description: "Implémentation d'une projection 3D pour le moteur de Tayasui Sketches, permettant de peindre sur un personnage en 3D avec des outils de peinture existants."
        },
        {
          title: "Rendu HDR / Shader IBL",
          description: "Création d'un outil de rendu basé sur des images HDR pour simuler l'éclairage naturel, donnant l'illusion de peindre dans un environnement réel."
        },
        {
          title: "Synchronisation iCloud",
          description: "Développement d'un format de fichier propriétaire pour sauvegarder les dessins en cours sur iCloud, permettant de les retrouver sur tous les appareils de l'utilisateur."
        },
        {
          title: "Gestion des achats in-app",
          description: "Intégration du système d'achats in-app StoreKit 2 d'Apple pour permettre aux utilisateurs d'acheter des modèles 3D supplémentaires."
        },
        {
          title: "Communauté Tayasui",
          description: "Ajout d'un espace communautaire dans l'application pour partager via CloudKit les créations des utilisateurs et découvrir celles des autres artistes."
        },
      ]
    },
    {
      image: FilbingBoxHero,
      thumbnail: FilbingBoxThumbnail,
      icon: FilbingBoxIcon,
      banner: FilbingBoxBanner,
      screenshots: [
        FilbingBoxScreenshot1,
        FilbingBoxScreenshot2,
        FilbingBoxScreenshot3,
        FilbingBoxScreenshot4,
        FilbingBoxScreenshot5,
      ],
      title: "Filbing Box",
      description: "La Filbing Box est un distributeur automatique à casiers conçu pour la vente de tous produits, alimentaires ou non, en libre-service. Associé à une application mobile, elle permet aux exploitants de gérer leur stock en remplaçant les produits vendus et en suivant les ventes en temps réel depuis leur smartphone.",
      info: "Derrière le concept efficace de la Filbing Box se cache une solution technique complexe, alliant électronique embarquée, logiciel de vente et application mobile de gestion.<br /><br />La carte électronique de contrôle du terminal de paiement et d'ouverture des casiers est reliée à un logiciel de vente Android embarqué dans le distributeur. L'application mobile, développée avec Flutter, permet de gérer à distance le stock et les ventes, en envoyant des commandes d'ouverture des casiers à la carte électronique.<br /><br />Des fonctionnalités supplémentaires sont également disponibles permettant un contrôle total du distributeur&nbsp;: gestion d'une liste de réapprovisionnement en fonction des dernières ventes, suivi de la température dans le distributeur (avec alerte en cas de dépassement des seuils), possibilité pour le client d'obtenir un reçu par e-mail, etc.",
      focus: "Nous avons conçu pour notre client une solution complète de gestion de son distributeur automatique&nbsp;: carte électronique de contrôle du terminal de paiement et d'ouverture des casiers, logiciel de vente et application mobile de gestion à distance.<br /><br />L'application mobile nécessitant une connexion sécurisée à la carte électronique afin de gérer le stock et d'ouvrir les casiers pour remplacer les produits, nous avons mis en place un système de communication sécurisé entre l'application et la solution embarquée dans le distributeur. Pour cela, nous avons développé une API RESTful intégrée à la carte électronique et sécurisée par un système d'authentification JWT&nbsp: ainsi, l'application Flutter fait des requêtes à l'API pour récupérer les informations de stock et envoyer les commandes d'ouverture des casiers.",
      slug: "filbing-box",
      tags: ["back-end","flutter","ios","android"],
      techs: ["Flutter","Java","C"],
      website: "https://www.filbing-distribution.com/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/filbing-box-v4/id1624718168",
        googlePlay: "https://play.google.com/store/apps/details?id=com.filbing.boxremote",
      },
      features: [
        {
          title: "Solution embarquée",
          description: "Développement d'une carte électronique dédiée au projet pour interfacer les communications série avec les casiers et le terminal de paiement."
        },
        {
          title: "Protocole 1-Wire",
          description: "Implémentation bit-banging du protocole 1-Wire pour la communication entre la carte électronique et les capteurs de température dans le distributeur."
        },
        {
          title: "Protocole SE1042",
          description: "Implémentation du protocole de transaction bancaire SE1042 d'Ingenico pour la communication entre la carte électronique et le terminal de paiement."
        },
        {
          title: "Base de données",
          description: "Développement d'une base de données locale au distributeur pour stocker les informations de stock et de ventes."
        },
        {
          title: "API RESTful",
          description: "Création d'une API RESTful intégrée au distributeur en Java pour permettre à l'application mobile de communiquer directement avec celui-ci via des sessions authentifiées par JWT."
        },
        {
          title: "Mises à jour OTA",
          description: "Mise en place par Gitlab CI/CD d'un système de mises à jour Over-The-Air pour permettre la mise à jour à distance du logiciel de vente embarqué dans le distributeur."
        },
        {
          title: "Serveur SSH",
          description: "Mise en place d'un pool de serveurs SSH pour permettre la connexion sécurisée des distributeurs à distance."
        },
      ]
    },
    {
      image: TrackAndBurnHero,
      thumbnail: TrackAndBurnThumbnail,
      icon: TrackAndBurnIcon,
      banner: TrackAndBurnBanner,
      screenshots: [
        TrackAndBurnScreenshot1,
        TrackAndBurnScreenshot2,
        TrackAndBurnScreenshot3,
        TrackAndBurnScreenshot4,
        TrackAndBurnScreenshot5,
        TrackAndBurnScreenshot6,
      ],
      title: "Track & Burn",
      description: "Pilotez une incroyable Muscle Car avec tout le punch et le drift du genre, filez à travers des paysages urbains ultra stylisés et concevez vos propres circuits. Défiez ensuite vos amis & rivalisez avec la communauté !",
      info: "Track and Burn demande aux joueuses et joueurs de parcourir tout un tas de pistes situées dans des villes, des banlieues ou à la campagne au volant d’une Muscle Car. Le tout prend place durant les années 70 et repose essentiellement sur les talents de conducteur des uns et des autres.<br /><br />Dans le mode Versus, les joueuses et joueurs tentent de remporter la victoire contre un adversaire (intelligence artificielle ou fantôme de quelqu’un d’autre) sur une piste changeant quotidiennement. Le mode Coast to Coast prend la forme d’une compétition divisée en six courses, et enfin le mode Triassic Tracks, qui doit être débloqué au préalable, prend place sur une piste préhistorique à base de lave, de rochers et… d’un T-Rex.",
      focus: "La mission de CDO Apps s'est essentiellement concentrée sur l'ajout de contenu lors de l'adaptation du jeu Steam sur iOS.<br /><br />Conformément aux spécifications du jeu original, nous avons développé les modes de jeu Coast to Coast (C2C) et Versus (VS) pour iOS. Ces modes de jeu s'articulent autour de courses générées semi-aléatoirement, avec un adversaire IA. Un nouveau comportement de l'IA a été développé permettant de mettre en scène des courses poursuites.<br /><br />En parallèle, nous avons assisté notre client pour la mise en ligne du jeu sur l'App Store.",
      slug: "track-and-burn",
      tags: ["unity","ios"],
      techs: ["Unity","C#"],
      stores: {
        appStore: "#",
        steam: "https://store.steampowered.com/app/1668170/Track_and_Burn/",
      },
      features: [
        {
          title: "Gameplay",
          description: "Développement des modes de jeu Coast to Coast et Versus, avec génération aléatoire des pistes et adversaires IA."
        },
        {
          title: "UI/UX",
          description: "Adaptation d'une expérience de jeu PC à une interface tactile, en respectant les standards de l'App Store."
        },
        {
          title: "Intelligence artificielle",
          description: "Création d'un comportement d'IA pour les adversaires, permettant des courses poursuites et des interactions dynamiques."
        },
        {
          title: "Contrôleurs de jeu",
          description: "Intégration de Unity Input API pour permettre de contrôler la voiture avec une manette de jeu (PlayStation, Xbox, etc)."
        },
        {
          title: "Achats intégrés",
          description: "Implémentation du système Unity IAP pour gérer les achats in-app de contenu exclusif sur iOS."
        },
      ],
    },
    {
      image: SSBEHero,
      thumbnail: SSBEThumbnail,
      icon: SSBEIcon,
      banner: SSBEBanner,
      screenshots: [
        SSBEScreenshot1,
        SSBEScreenshot2,
      ],
      title: "SSBE.re",
      description: "SSBE.re est le site du Sport Santé à La Réunion. Pour toute personne recherchant une activité physique à la Réunion, SSBE.re est le site de référence pour trouver des activités adaptées à ses besoins. Que vous souhaitiez pratiquer une activité pour le plaisir, la convivialité ou pour améliorer et renforcer votre santé, des structures proposent des créneaux sur toute l’île à retrouver sur SSBE.re.",
      focus: "La DRAJES de la Réunion a souhaité moderniser la gestion de labellisation des structures Sport Santé de l'île. Afin de remplacer les formulaires papier, nous avons développé une solution Firebase permettant aux structures de déposer leur dossier de labellisation en ligne, et aux administrateurs de gérer les demandes et de valider les dossiers.<br /><br />Un système de mise en ligne automatique a également été mis en place pour publier les structures labellisées sur le site WordPress SSBE.re&nbsp: ainsi, sans intervention administrative lourde, les structures labellisées et leurs activités sont visibles par le public sur le site.",
      slug: "ssbe-re",
      tags: ["back-end"],
      techs: ["Firebase","JavaScript"],
      website: "https://ssbe.re/",
    },
    {
      image: JetItNowHero,
      thumbnail: JetItNowThumbnail,
      icon: JetItNowIcon,
      banner: JetItNowBanner,
      screenshots: [
        JetItNowScreenshot1,
        JetItNowScreenshot2,
        JetItNowScreenshot3,
        JetItNowScreenshot4,
        JetItNowScreenshot5,
        JetItNowScreenshot6,
        JetItNowScreenshot7,
        JetItNowScreenshot8,
        JetItNowScreenshot9,
      ],
      title: "JetItNow",
      description: "JetItNow est la première application qui permet de réserver un vol en jet privé en payant directement via l'application à un tarif fixe sans contact direct avec un opérateur aérien. Rapide et simple d’utilisation, il est possible de payer les vols directement par carte bancaire, Paypal ou virement bancaire.",
      focus: "CDO Apps a accompagné JetItNow sur l'ensemble du projet. Nous avons tout d'abord défini les spécifications de l'application selon les besoins du client. Nous avons mis en relation JetItNow avec notre partenaire pour la réalisation des maquettes et du design de l'application. Nous avons ensuite développé une application Flutter en respectant les délais et les spécifications définies.<br /><br />Enfin, nous avons pris en charge la publication de l'application sur les stores. Par la suite, nous avons assuré la maintenance évolutive de l'application, ajoutant notamment une interface de gestion des réservations via une web app, également développée avec Flutter.",
      slug: "jetitnow",
      tags: ["mobile-apps","project-management","flutter","ios","android"],
      techs: ["Flutter","Firebase"],
      website: "https://www.jetitnow.com/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/jetitnow/id1586848363?mt=8",
        googlePlay: "#"
      }
    },
    {
      image: SketchesHero,
      thumbnail: SketchesThumbnail,
      icon: SketchesIcon,
      banner: SketchesBanner,
      screenshots: [
        SketchesScreenshot1,
        SketchesScreenshot2,
        SketchesScreenshot3,
        SketchesScreenshot4,
        SketchesScreenshot5,
        SketchesScreenshot6,
        SketchesScreenshot7,
        SketchesScreenshot8,
        SketchesScreenshot9,
      ],
      title: "Tayasui Sketches",
      description: "Sketches est l'application de dessin aux outils les plus réalistes, dotée d’un grand nombre de fonctions pointues et d'une interface minimaliste et intuitive.",
      info: "Dans la suite d'applications artistiques Tayasui, Sketches est la pionnière de la gamme : elle a complètement défini le moteur de dessin et l'interface utilisateur des applications suivantes. Avec ses outils de dessin réalistes et des fonctionnalités avancées, Sketches est une application majeure de dessin sur l'App Store.<br /><br />Plusieurs fois primée aux App Store Awards, Sketches est utilisée par des millions d'artistes à travers le monde. Disponible sur smartphone et tablette, elle trouve tout son potentiel sur iPad, avec l'Apple Pencil, grâce à une ergonomie pensée pour les tablettes : créer de beaux dessins, retoucher des images, partager avec ses proches et ses amis n’a jamais été aussi efficace et agréable !",
      focus: "Nous avons travaillé en étroite collaboration avec l'équipe de Tayasui pour concevoir une application de dessin complète. En plus du moteur de dessin dédié, nous avons réalisé l'architecture de l'application selon les spécifications de Tayasui, en suivant les préceptes Human Interface Guidelines d'Apple pour garantir une expérience utilisateur optimale.<br /><br />Notre travail a donc porté sur l'ensemble du projet, de l'interface utilisateur aux interactions avec le moteur de dessin, en passant par la gestion des fichiers, des paramètres et des achats in-app. Nous avons également mis en place un système de synchronisation des dessins sur iCloud pour permettre aux utilisateurs de retrouver leurs créations sur tous leurs appareils.",
      slug: "sketches",
      tags: ["mobile-apps","ios"],
      techs: ["Swift","Objective-C","C","OpenGL"],
      website: "https://www.tayasui.com/sketches/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/tayasui-sketches/id641900855",
      },
      features: [
        {
          title: "Moteur de dessin",
          description: "Développement d'un moteur de dessin OpenGL permettant de gérer les outils de peinture, les calques et les effets visuels en temps réel."
        },
        {
          title: "Interface utilisateur",
          description: "Conception d'une interface utilisateur minimaliste et intuitive, permettant de dessiner sur iPad avec l'Apple Pencil de manière naturelle."
        },
        {
          title: "Gestion des fichiers",
          description: "Création d'un système de gestion de fichiers pour enregistrer, ouvrir et exporter les dessins dans différents formats (PNG, JPEG, PSD)."
        },
        {
          title: "Achats intégrés",
          description: "Intégration du système d'achats in-app StoreKit 2 d'Apple pour permettre aux utilisateurs d'acheter des outils de dessin supplémentaires."
        },
        {
          title: "Synchronisation iCloud",
          description: "Développement d'un format de fichier propriétaire pour sauvegarder les dessins en cours sur iCloud, permettant de les retrouver sur tous les appareils de l'utilisateur."
        },
        {
          title: "Communauté Tayasui",
          description: "Ajout d'un espace communautaire dans l'application pour partager via CloudKit les créations des utilisateurs et découvrir celles des autres artistes."
        },
        {
          title: "Multitâche iPad",
          description: "Optimisation de l'application pour le multitâche sur iPad, permettant de dessiner en split view ou en slide over avec d'autres applications."
        },
        {
          title: "MacOS Catalyst",
          description: "Adaptation de l'application pour macOS en utilisant Catalyst, permettant aux utilisateurs de dessiner sur leur Mac avec une interface adaptée."
        },
      ],
    },
    {
      image: BiodermaLiveQuizHero,
      thumbnail: BiodermaLiveQuizThumbnail,
      icon: BiodermaLiveQuizIcon,
      banner: BiodermaLiveQuizBanner,
      screenshots: [
        BiodermaLiveQuizScreenshot1,
        BiodermaLiveQuizScreenshot2,
        BiodermaLiveQuizScreenshot3,
        BiodermaLiveQuizScreenshot4,
        BiodermaLiveQuizScreenshot5,
      ],
      title: "BeBioderma : Live Quiz",
      description: "Le Live Quiz BeBioderma est un dispositif innovant qui offre une expérience en live à toute la communauté de la marque Bioderma. Les Live Quiz sont composés d’un flux vidéo associé à une série de questions / réponses et à des gains pour chacune des manches.",
      info: "L'application est réservée aux membres de la communauté BeBioderma. Après s'être connecté à son compte, l'utilisateur peut en un clic retrouver son programme personnalisé, développer son expertise dermato et remplir sa cagnotte : formation, challenge, boite à outils, actualité Bioderma.<br /><br />En exclusivité dans cette application : les Live Quiz. Régulièrement, un expert Bioderma anime un quiz en direct sur un thème spécifique. Les participants peuvent remplir leur cagnotte en répondant correctement aux questions.",
      slug: "be-bioderma-live-quiz",
      tags: ["ios","android"],
      techs: ["Swift","Kotlin"],
      website: "https://www.be.naos.fr/",
      stores: {
        appStore: "#",
        googlePlay: "#",
      }
    },
    {
      image: SillyMemoryHero,
      thumbnail: SillyMemoryThumbnail,
      icon: SillyMemoryIcon,
      banner: SillyMemoryBanner,
      screenshots: [
        SillyMemoryScreenshot1,
        SillyMemoryScreenshot2,
        SillyMemoryScreenshot3,
        SillyMemoryScreenshot4,
        SillyMemoryScreenshot5,
        SillyMemoryScreenshot6,
        SillyMemoryScreenshot7,
        SillyMemoryScreenshot8,
      ],
      title: "Silly Memory",
      description: "Silly Memory est un puzzle game qui demande mémoire et rapidité. Le joueur évolue à travers 30 niveaux scénarisés, dans lesquels il rencontre divers personnages et relève de nombreux défis.",
      info: "En incarnant Gorzbul, un alien loufoque, le joueur est entraîné dans une aventure pleine de surprises. Il doit mémoriser des séquences de couleurs et de formes pour les matcher dans le temps imparti et progresser dans l'histoire. Chaque niveau est un nouveau défi, avec des mécaniques de jeu accessibles à tous.<br /><br />Le jeu est entièrement gratuit mais des publicités et des achats intégrés permettent de débloquer des bonus pour faciliter la progression. Les joueurs peuvent également défier leurs amis et comparer leurs scores sur le classement mondial.",
      focus: "Il s'agit de la première production éditée par CDO Apps. Nous avons développé Silly Memory de A à Z, de la conception du Game Design Document à la publication sur les stores en passant par le développement du jeu.<br /><br />Nous avons créé un moteur de jeu en OpenGL spécifique pour Silly Memory, le CDO Engine, permettant de gérer les animations, les effets visuels et les interactions avec l'utilisateur. Nous avons également mis en place un back-end Firebase pour valider les achats in-app et gérer les scores des joueurs et les classements.",
      slug: "silly-memory",
      tags: ["ios","android"],
      techs: ["Objective-C","Java","C","OpenGL"],
      website: "https://www.sillymemory.com/",
      stores: {
        appStore: "#",
        googlePlay: "#",
      },
      features: [
        {
          title: "Game Design",
          description: "Conception de 30 niveaux scénarisés, avec des personnages et des défis variés pour une expérience de jeu complète."
        },
        {
          title: "Moteur de jeu",
          description: "Développement du CDO Engine, un moteur de jeu OpenGL développé en C afin d'assurer facilement le fonctionnement multiplateforme du jeu."
        },
        {
          title: "Achats intégrés",
          description: "Mise en place d'un back-end Firebase pour valider les achats in-app depuis les API StoreKit d'Apple et Billing de Google."
        },
        {
          title: "Monétisation par la publicité",
          description: "Intégration d'AdMob comme médiateur publicitaire pour afficher des publicités vidéo en échange de bonus pour les joueurs."
        },
        {
          title: "Classement mondial",
          description: "Création d'un système de classement mondial pour comparer les scores des joueurs et offrir des récompenses aux meilleurs par notifications push."
        },
      ],
    },
    {
      image: TheotokosHero,
      thumbnail: TheotokosThumbnail,
      icon: TheotokosIcon,
      banner: TheotokosBanner,
      screenshots: [
        TheotokosScreenshot1,
        TheotokosScreenshot2,
        TheotokosScreenshot3,
        TheotokosScreenshot4,
      ],
      title: "Theotokos",
      description: "Theotokos, l'application du site leader de la rencontre chrétienne propose à ses utilisateurs de trouver leur âme sœur en ligne.",
      slug: "theotokos",
      tags: ["ios","android"],
      techs: ["Objective-C","Java"],
      wesite: "https://www.theotokos.fr/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/theotokos-rencontre-chr%C3%A9tienne/id1525219542",
        googlePlay: "https://play.google.com/store/apps/details?id=com.eleousa.theotokosapp",
      }
    },
    {
      image: SkicaseHero,
      thumbnail: SkicaseThumbnail,
      icon: SkicaseIcon,
      banner: SkicaseBanner,
      screenshots: [
        SkicaseScreenshot1,
        SkicaseScreenshot2,
        SkicaseScreenshot3,
      ],
      title: "Skicase",
      description: "Skicase est une solution complète de consignes à skis en libre-service, basé sur un système informatisé. Localisées au pied des pistes, les consignes Skicase améliorent grandement l’expérience des skieurs.",
      slug: "skicase",
      tags: ["ios","android"],
      techs: ["Objective-C","Java"],
      website: "https://myskicase.com/",
      stores: {
        appStore: "#",
        googlePlay: "#",
      }
    },
    {
      image: BiodermaCommerciauxHero,
      thumbnail: BiodermaCommerciauxThumbnail,
      icon: BiodermaCommerciauxIcon,
      banner: BiodermaCommerciauxBanner,
      screenshots: [
        BiodermaCommerciauxScreenshot1,
        BiodermaCommerciauxScreenshot2,
        BiodermaCommerciauxScreenshot3,
        BiodermaCommerciauxScreenshot4,
      ],
      title: "BeBioderma : Commerciaux",
      description: "BeBioderma Commerciaux est une application dédiée aux commerciaux de la marque Bioderma. Elle leur permet d'avoir un espace de gestion et de suivi des activités liées au programme BeBioderma.",
      slug: "be-bioderma-commerciaux",
      tags: ["android"],
      techs: ["Java"],
    },
    {
      image: ImerysHero,
      thumbnail: ImerysThumbnail,
      icon: ImerysIcon,
      banner: ImerysBanner,
      screenshots: [
        ImerysScreenshot1,
        ImerysScreenshot2,
        ImerysScreenshot3,
        ImerysScreenshot4,
        ImerysScreenshot5,
        ImerysScreenshot6,
      ],
      title: "Imerys",
      description: "Imerys est la référence pour les professionnels de la toiture. L'application Imerys Toiture permet de trouver les produits et les services adaptés à chaque projet de toiture.",
      info: "L'application Imery toiture est disponible sur les stands de la marque au salon professionnel de la construction Batimat : elle permet à tout visiteur d'obtenir des informations sur les produits Imerys et de trouver le produit adapté à son projet de toiture.<br /><br />L'application est également utilisée par les commerciaux d'Imerys pour présenter les produits et les services de l'entreprise à leurs clients en leur permettant de rechercher efficacement des fiches produits, des fiches techniques et des documentations commerciales pour les inclure dans un panier de documents à envoyer par e-mail.",
      slug: "imerys",
      tags: ["ios"],
      techs: ["Objective-C"],
      features: [
        {
          title: "API Batimat",
          description: "Intégration de l'API du salon Batimat pour récupérer les informations pré-enregistrées des visiteurs et leur faciliter l'envoi de documentation."
        },
        {
          title: "Code barre",
          description: "Développement d'un lecteur de code barre pour scanner les badges visiteurs et récupérer leurs informations de contact via l'API Batimat."
        }
      ]
    },
    {
      image: WatchLiveHero,
      thumbnail: WatchLiveThumbnail,
      icon: WatchLiveIcon,
      banner: WatchLiveBanner,
      screenshots: [
        WatchLiveScreenshot1,
        WatchLiveScreenshot2,
        WatchLiveScreenshot3,
        WatchLiveScreenshot4,
      ],
      title: "FHH Watch Live",
      description: "Watch Live est la seule application offrant un accès exclusif au contenu sur mesure de 9 prestigieuses Maisons horlogères et de la FHH Academy.",
      slug: "fhh-watch-live",
      tags: ["ios"],
      techs: ["Objective-C"],
      website: "https://watchlive.hautehorlogerie.org/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/watch-live-fhh-academy/id1265921664"
      }
    },
    {
      image: RichessesDuTerroirHero,
      thumbnail: RichessesDuTerroirThumbnail,
      icon: RichessesDuTerroirIcon,
      banner: RichessesDuTerroirBanner,
      screenshots: [
        RichessesDuTerroirScreenshot1,
        RichessesDuTerroirScreenshot2,
        RichessesDuTerroirScreenshot3,
        RichessesDuTerroirScreenshot4,
        RichessesDuTerroirScreenshot5,
      ],
      title: "Les Richesses du Terroir",
      description: "Richesses Du Terroir c’est la vente en circuit court de plats préparés à partir de produits frais, locaux et de saison. Le consommateur les achète par le biais de distributeurs automatiques.",
      slug: "richesses-du-terroir",
      tags: ["ios"],
      techs: ["Objective-C"]
    },
    {
      image: SLVHero,
      thumbnail: SLVThumbnail,
      icon: SLVIcon,
      banner: SLVBanner,
      screenshots: [
        SLVScreenshot1,
        SLVScreenshot2,
        SLVScreenshot3,
        SLVScreenshot4,
      ],
      title: "SLV",
      description: "La boîte à outils pour tous vos projets luminaires : SLV assure la conception, la fabrication et la commercialisation de luminaires contemporains pour le résidentiel, l’hôtellerie et la restauration, le commerce, le tertiaire et l’extérieur.",
      slug: "slv",
      tags: ["ios","android"],
      techs: ["Objective-C","Java"],
      website: "https://www.slv.com/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/slv-france/id1176289130",
        googlePlay: "https://play.google.com/store/apps/details?id=com.slv.pocket",
      }
    },
    {
      image: WatchEssentialsHero,
      thumbnail: WatchEssentialsThumbnail,
      icon: WatchEssentialsIcon,
      banner: WatchEssentialsBanner,
      screenshots: [
        WatchEssentialsScreenshot1,
        WatchEssentialsScreenshot2,
        WatchEssentialsScreenshot3,
      ],
      title: "FHH Watch Essentials",
      description: "La formation Watch Essentials a déjà fait ses preuves au sein des plus grandes Maisons horlogères, avec plus de 33 000 personnes formées à travers le monde. La Fondation de la Haute Horlogerie vous offre désormais la possibilité de la suivre en ligne, porter par un ensemble de vidéo, aux côtés d'experts dans leur domaine.",
      slug: "fhh-watch-essentials",
      tags: ["ios", "android"],
      techs: ["Objective-C","Java"],
      website: "https://www.hautehorlogerie.org/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/watch-essentials/id1141456162",
        googlePlay: "https://play.google.com/store/apps/details?id=com.fhh.watchessentials",
      }
    },
    {
      image: CDOEngineHero,
      thumbnail: CDOEngineThumbnail,
      icon: CDOEngineIcon,
      banner: CDOEngineBanner,
      title: "CDO Engine",
      description: "CDO Engine est un moteur graphique développé par CDO Apps pour créer des jeux vidéo en 2D/3D. Il est conçu pour être léger, performant et facile à utiliser. Écrit en C, reposant sur OpenGL, il est compatible avec les principaux systèmes d'exploitation, notamment Windows, macOS, iOS et Android.",
      focus: "Nous avons développé CDO Engine pour répondre à nos besoins internes de développement de jeux vidéo. Ce moteur graphique est utilisé pour la plupart de nos projets 2D/3D, offrant une grande flexibilité et une optimisation poussée pour garantir une expérience de jeu fluide. Nous avons également développé un ensemble d'outils pour faciliter la conversion et l'intégration des assets 2D/3D, audio et polices de caractères.<br /><br />Récemment, un éditeur graphique a été ajouté à la solution pour permettre la création et la gestion des scènes de jeu&nbsp;: totalement intégré à l'environnement Xcode, il permet de visualiser en temps réel les changements apportés à la scène et facilite la personnalisation des comportements des objets grâce à un système de plugins.",
      slug: "cdo-engine",
      tags: ["graphics"],
      techs: ["Objective-C","C","OpenGL"],
      features: [
        {
          title: "Éditeur graphique",
          description: "Création d'un éditeur graphique intégré à Xcode pour faciliter la création et la gestion des scènes de jeu."
        },
        {
          title: "Outils de conversion",
          description: "Gestion des formats de fichiers 2D/3D (PNG, JPEG, SVG, FBX, OBJ, etc.) pour faciliter l'intégration des assets dans un projet."
        },
        {
          title: "Audio",
          description: "Intégration OpenAL pour gérer les effets sonores 3D et la musique. Formats compatibles : WAV, MP3, OGG, etc."
        },
        {
          title: "Animation",
          description: "Moteur d'animation par keyframes pour gérer les animations 2D/3D des objets : opacité, mouvement, squelette, etc."
        },
        {
          title: "Plugins",
          description: "Mise en place d'un système de plugins pour personnaliser les comportements des objets dans les scènes de jeu (scripts Lua, C, JavaScript)."
        },
        {
          title: "Effets visuels",
          description: "Shaders OpenGL pour gérer les effets visuels avancés : Shell texturing, Cel shading, Physically based rendering , Image-based lighting, etc."
        },
        {
          title: "Order-independent transparency",
          description: "Implémentation d'un algorithme par depth peeling optimisé pour mobile afin de gérer la transparence des objets 3D."
        },
        {
          title: "Z-index",
          description: "Gestion des calques d'affichage pour gérer l'ordre de dessin des objets, particulièrement utile pour l'UI ou les sprites 2D."
        },
        {
          title: "Particules",
          description: "Système de particules avancé pour créer des effets visuels complexes (feu, fumée, explosion, etc.)."
        }
      ]
    },
    {
      image: PuissanceNintendoHero,
      thumbnail: PuissanceNintendoThumbnail,
      icon: PuissanceNintendoIcon,
      banner: PuissanceNintendoBanner,
      screenshots: [
        PuissanceNintendoScreenshot1,
        PuissanceNintendoScreenshot2,
        PuissanceNintendoScreenshot3,
        PuissanceNintendoScreenshot4,
        PuissanceNintendoScreenshot5,
        PuissanceNintendoScreenshot6,
        PuissanceNintendoScreenshot7,
        PuissanceNintendoScreenshot8,
      ],
      title: "Puissance Nintendo",
      description: "Suivez l'actualité des jeux vidéo des consoles Nintendo en temps réel avec l'application officielle de Puissance Nintendo, qui reprend les news rédigées par l'équipe de passionnés : consoles et jeux Nintendo Switch, 3DS, Wii U n'auront plus aucun secret pour vous !",
      slug: "puissance-nintendo",
      tags: ["ios"],
      techs: ["Objective-C"],
      website: "https://www.p-nintendo.com/",
      stores: {
        appStore: "https://apps.apple.com/fr/app/puissance-nintendo-lactu-switch-3ds-et-wii-u/id979005544",
      }
    }
];

function Query({filter, key, sort, ascending, limit, offset} = {}) {
  let data = DataSource;

  if (filter) {
    key = key || "tags";
    data = data.filter(project => {
      const values = project[key];
      if (typeof values === "string") {
        return values === filter;
      } else if (Array.isArray(values)) {
        return values.includes(filter);
      } else {
        return false;
      }
    });
  }

  if (sort) {
    ascending = ascending || true;
    data = data.sort((a, b) => {
      const valueA = a[sort];
      const valueB = b[sort];
      if (typeof valueA === "string") {
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else {
        return 0;
      }
    });
  }

  if (limit) {
    const length = data.length;
    offset = offset || 0;
    if (offset >= length) {
      return [];
    }

    limit = Math.min(length - offset, limit);
    data = data.slice(offset, offset + limit);
  }

  return data;
}

export default Query;